import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtVerify, SignJWT } from 'jose';

const LinkLineConnectPage = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    gameid: "",
    password: "",
    secretQuestion: "",
    secretAnswer: "",
    email: "",
    acceptedTerms: false,
    charName: "",
    charCount: 0,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [flow, setFlow] = useState(null);
  const [lineUserID, setLineUserID] = useState(null);
  const [refCode, setRefCode] = useState(null);
  const [otp, setOtp] = useState(null);
  const [action, setAction] = useState(null);

  const secretQuestions = [
    { value: "q1", label: "สัตว์เลี้ยงตัวแรกของคุณชื่ออะไร" },
    { value: "q2", label: "รถคันแรกของคุณยี่ห้ออะไร" },
    { value: "q3", label: "คุณเกิดที่เมืองอะไร" },
    { value: "q4", label: "นามสกุลเดิมของแม่คุณคืออะไร" },
    { value: "q5", label: "หนังสือเล่มโปรดของคุณคือเล่มไหน" },
    { value: "q6", label: "โรงเรียนแรกที่คุณเรียนชื่ออะไร" },
    { value: "q7", label: "อาหารจานโปรดของคุณคืออะไร" },
    { value: "q8", label: "ชื่อกลางของพ่อคุณคืออะไร" },
    { value: "q9", label: "นายจ้างคนแรกของคุณชื่ออะไร" },
    { value: "q10", label: "เพื่อนสนิทสมัยเด็กของคุณชื่ออะไร" },
  ];

  // Decode token and set state
  useEffect(() => {
    const decodeToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      //console.log("Token:", token);

      if (token) {
        try {
          const key = new TextEncoder().encode("dbf0450af36284d45b9ebddf166f3aa278d19091f27362c542525fdac56e4b9f");
          const { payload } = await jwtVerify(token, key);

          // Set your state values here
          setFlow(payload.flow);
          setLineUserID(payload.lineUserID);
          setRefCode(payload.refCode);
          setOtp(payload.otp);
          setAction(payload.action);

        } catch (error) {
          console.error("Invalid token:", error);
          setErrors({ apiError: "ข้อมูลไม่ถูกต้องกรุณาตรวจสอบ URL" });
        }
      } else {
        setErrors({ apiError: "ข้อมูลไม่ถูกต้องกรุณาตรวจสอบ URL" });
      }
    };

    decodeToken(); // Call the async function
  }, []); // Empty dependency array ensures this runs only once

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstname) newErrors.firstname = "กรุณากรอก ชื่อจริง";
    if (!formData.lastname) newErrors.lastname = "กรุณากรอก นามสกุล";
    if (!formData.gameid) newErrors.gameid = "กรุณากรอก เกมไอดี";
    if (!formData.password) newErrors.password = "กรุณากรอก รหัสผ่านที่ถูกต้อง";
    if (!formData.secretQuestion)
      newErrors.secretQuestion = "เลือกคำถามความปลอดภัย";
    if (!formData.secretAnswer)
      newErrors.secretAnswer = "กรุณากรอก คำตอบคำถามที่เลือก";
    if (!formData.acceptedTerms)
      newErrors.acceptedTerms = "ผู้เล่นต้องยืนยันว่าข้อมูลที่กรอกเป็นความจริงก่อน";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    if (!validateForm()) return;

    try {
      setLoading(true);
      const headers = {
        "Accept-Language": "th",
        "Accept": "application/json;charset=UTF-8",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": "ss3+Nn9s%!$$DJ35bXISQYC!c+^P4x3Rk*2nWB3Q%$k29yUzLAthISeXa%d2LMvG",
      };
      const secret = new TextEncoder().encode("dbf0450af36284d45b9ebddf166f3aa278d19091f27362c542525fdac56e4b9f");  
      const alg = 'HS256'
      const jwt = await new SignJWT({ ...formData, refCode, otp, lineUserID, action, flow })
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setExpirationTime('2h')
        .sign(secret);
      const response = await axios.post(
        "https://apis.ranworld-adventures.com/v1/authentication/link/lineconnect",
        { token: jwt },
        { headers }
      );
      setSuccessMessage("ผูกบัญชีไลน์กับไอดีเกมเรียบร้อยครับ");
      setFormData({
        firstname: "",
        lastname: "",
        gameid: "",
        password: "",
        secretQuestion: "",
        secretAnswer: "",
        email: "",
        acceptedTerms: false,
        charName: "",
        charCount: 0,
      });
      setErrors({});
    } catch (error) {
      console.error("Error submitting registration:", error);
      setErrors({
        apiError: error.response?.data || "An unexpected error occurred.",
      });
    } finally {
      setLoading(false);
    }
  };

  const ErrorModalNew = ({ title, message, onClose }) => {
    return (
      <div className="modal-overlay">
        <div className="modal2">
          <h3>{title}</h3>
          <p>{message}</p>
          <button onClick={onClose}>ปิด</button>
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: "20px", maxWidth: "400px", margin: "auto", fontFamily: "Arial, sans-serif" }}>
      {/* <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img 
          src="https://thenewadventuresimages.s3.us-east-1.amazonaws.com/Add+a+heading+(5).png" 
          alt="True Money Logo" 
          style={{ maxWidth: "auto", height: "90px" }} 
        />
      </div> */}

      {errors.apiError && (
        <ErrorModalNew title="ขออภัย" message={errors.apiError} onClose={() => setErrors({ apiError : null})}/>
      )}

      {successMessage && (
        <div
          style={{
            color: "green",
            marginBottom: "20px",
            padding: "10px",
            border: "1px solid green",
            borderRadius: "5px",
            backgroundColor: "#dfd",
          }}
        >
          {successMessage}
        </div>
      )}

      {/* Display Query Parameters */}
      <div
        style={{
          marginBottom: "20px",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {[
            { title: "รหัสอ้างอิง", value: refCode },
          ].map(({ title, value }) => (
            <li
              key={title}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 0",
              }}
            >
              <span style={{ fontSize: "14px", color: "#555", flex: 1 }}>{title}:</span>
              <span style={{ fontSize: "14px", color: "#555", flex: 1, textAlign: "right" }}>{value || "N/A"}</span>
            </li>
          ))}
        </ul>
      </div>

      <form onSubmit={handleSubmit}>

        {/* Game ID */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            เกมไอดี ที่เคยสมัคร:
            <input
              type="text"
              name="gameid"
              placeholder="กรอกเกมไอดี ที่เคยสมัคร"
              value={formData.gameid}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
            {errors.gameid && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.gameid}
              </span>
            )}
          </label>
        </div>

        {/* Password */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            รหัสผ่านที่ถูกต้อง:
            <input
              type="password"
              name="password"
              placeholder="กรอกรหัสผ่าน"
              value={formData.password}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
            {errors.password && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.password}
              </span>
            )}
          </label>
        </div>

        {/* First Name */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            ชื่อ ที่เคยสมัคร:
            <input
              type="text"
              name="firstname"
              placeholder="กรอก ชื่อจริง"
              value={formData.firstname}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
            {errors.firstname && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.firstname}
              </span>
            )}
          </label>
        </div>

        {/* Last Name */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            นามสกุล ที่เคยสมัคร:
            <input
              type="text"
              name="lastname"
              placeholder="กรอก นามสกุล"
              value={formData.lastname}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
            {errors.lastname && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.lastname}
              </span>
            )}
          </label>
        </div>

        {/* Secret Question */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            คำถามความปลอดภัย ที่เคยสมัคร:
            <select
              name="secretQuestion"
              value={formData.secretQuestion}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            >
              <option value="">เลือกคำถามความปลอดภัย</option>
              {secretQuestions.map((question) => (
                <option key={question.value} value={question.value}>
                  {question.label}
                </option>
              ))}
            </select>
            {errors.secretQuestion && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.secretQuestion}
              </span>
            )}
          </label>
        </div>

        {/* Secret Answer */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            คำตอบ ที่เคยสมัคร (กรอกเท่าที่จำได้):
            <input
              type="text"
              name="secretAnswer"
              placeholder="กรอกคำตอบตวามความจริง ระบบจะทำการตรวจสอบข้อมูล"
              value={formData.secretAnswer}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
            {errors.secretAnswer && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.secretAnswer}
              </span>
            )}
          </label>
        </div>

        {/* Email */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            อีเมล (ถ้ามี):
            <input
              type="email"
              name="email"
              placeholder="อีเมลที่ใช้สมัครไอดีเกม"
              value={formData.email}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
          </label>
        </div>

        {/* Char Name */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            ชื่อตัวละครในไอดี (ถ้ามี):
            <input
              type="name"
              name="charName"
              placeholder="ชื่อตัวละครในไอดี"
              value={formData.charName}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
          </label>
        </div>

        {/* Char Count */}
        <div style={{ marginBottom: "15px" }}>
          <label>
            จำนวนตัวละครทั้งหมดในไอดี (ถ้ามี):
            <input
              type="number"
              name="charCount"
              placeholder="จำนวนตัวละครทั้งหมดในไอดี"
              value={formData.charCount}
              onChange={handleInputChange}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            />
          </label>
        </div>

        {/* Terms and Conditions */}
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              name="acceptedTerms"
              checked={formData.acceptedTerms}
              onChange={handleInputChange}
              style={{
                marginRight: "10px",
                transform: "scale(1.2)",
                cursor: "pointer",
              }}
            />
            ฉันขอยืนยันว่าข้อมูลที่กรอก เป็นความจริง{" "} และหากโดนสวมรอยแอบอ้าง แอดมินขอสงวนสิทธิ์ในการกู้บัญชีในทุกกรณี
          </label>
          {errors.acceptedTerms && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {errors.acceptedTerms}
            </span>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: loading ? "#ccc" : "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            fontSize: "16px",
            cursor: loading ? "not-allowed" : "pointer",
            boxSizing: "border-box",
          }}
        >
          {loading ? "กำลังตรวจสอบ..." : "ยืนยันข้อมูล"}
        </button>
      </form>

    </div>
  );
};

export default LinkLineConnectPage;
