import React from 'react';
import { Routes, Route } from 'react-router-dom';
import App from './App'; // Main application component
import RegistrationPage from './pages/signup'; // Main application component
import LinkLineConnectPage from './pages/linkgameid'; // Main application component

const MainApp = () => {
  return (
    <Routes>
      {/* Define your routes here */}
      <Route path="/" element={<App />} />
      <Route path="/signup" element={<RegistrationPage />} />
      <Route path="/linklineconnect" element={<LinkLineConnectPage />} />
    </Routes>
  );
};

export default MainApp;
