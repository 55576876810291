import axios from "axios";
import { jwtVerify } from 'jose';
import QRCodeStyling from "qr-code-styling";
import React, { useEffect, useState, useRef } from "react";
import templateImagePath from "./images/template.jpg"; // Path to your template image

const qrCode = new QRCodeStyling({
  width: 350,
  height: 350,
  dotsOptions: {
    color: "#000000",
    type: "square"
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 1
  }
});

const App = () => {

  const ref = useRef(null);

  const predefinedAmounts = [50, 100, 150, 300, 500, 1000, 1500, 3000, 5000, 7000, 10000]; // Predefined amounts
  const [amount, setAmount] = useState("");
  const [customAmount, setCustomAmount] = useState(false); // Toggle between predefined and free input
  const [imageDataURL, setImageDataURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [referenceNumber, setReferenceNumber] = useState(null);
  const [gameid, setGameid] = useState(null);
  const [flow, setFlow] = useState(null);
  const [lineUserID, setLineUserID] = useState(null);
  const [refCode, setRefCode] = useState(null);
  const [otp, setOtp] = useState(null);
  const [action, setAction] = useState(null);
  const [qrData, setQrData] = React.useState(null);

  // Decode token and set state
  useEffect(() => {
    const decodeToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      //console.log("Token:", token);

      if (token) {
        try {
          const key = new TextEncoder().encode("dbf0450af36284d45b9ebddf166f3aa278d19091f27362c542525fdac56e4b9f");
          const { payload } = await jwtVerify(token, key);
          // Set your state values here
          setReferenceNumber(payload.referenceNumber);
          setGameid(payload.gameid);
          setFlow(payload.flow);
          setLineUserID(payload.lineUserID);
          setRefCode(payload.refCode);
          setOtp(payload.otp);
          setAction(payload.action);
        } catch (error) {
          console.error("Invalid token:", error);
          setError("ข้อมูลไม่ถูกต้องกรุณาตรวจสอบ URL");
        }
      } else {
        setError("ข้อมูลไม่ถูกต้องกรุณาตรวจสอบ URL");
      }
    };

    decodeToken(); // Call the async function
  }, []); // Empty dependency array ensures this runs only once


  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, []); // Only append QR code once on mount
  
  useEffect(() => {

    const createTemplateImage = async (amount, mobileNumber, referenceNumber) => {
      try {
        // Generate QR code as raw PNG data
        const qrImageData = await qrCode.getRawData("png");
        const qrImage = new Image();
        qrImage.src = URL.createObjectURL(qrImageData);
    
        // Load template image
        const templateImage = new Image();
        templateImage.src = templateImagePath;
    
        // Ensure both images are loaded before proceeding
        await Promise.all([
          new Promise((resolve, reject) => {
            qrImage.onload = resolve;
            qrImage.onerror = reject;
          }),
          new Promise((resolve, reject) => {
            templateImage.onload = resolve;
            templateImage.onerror = reject;
          }),
        ]);
    
        // Set up canvas
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
    
        // Set canvas dimensions to match the template image
        canvas.width = templateImage.width;
        canvas.height = templateImage.height;
    
        // Draw the template image
        context.drawImage(templateImage, 0, 0);
    
        // Define QR code position and size
        const qrCodeWidth = 350; // Adjust as needed
        const qrCodeHeight = 350;
        const qrCodePosition = {
          x: (canvas.width - qrCodeWidth) / 2,
          y: (canvas.height - qrCodeHeight + 100) / 2,
        };
    
        // Draw the QR code on the canvas
        context.drawImage(qrImage, qrCodePosition.x, qrCodePosition.y, qrCodeWidth, qrCodeHeight);
    
        // Add text to the canvas
        context.font = "bold 30px Arial"; // Font size and style
        context.fillStyle = "black"; // Text color
        context.textAlign = "center"; // Center text horizontally
    
        // Reference number
        const textReferenceNo = `Reference Number: ${referenceNumber}`;
        context.fillText(textReferenceNo, canvas.width / 2, canvas.height - 260);
    
        // Mobile number
        const textTxd = `บัญชีปลายทาง: ${mobileNumber}`;
        context.fillText(textTxd, canvas.width / 2, canvas.height - 200);
    
        // Amount
        const textAmount = `จำนวนเงิน ${Number(amount).toLocaleString()} บาท`;
        context.font = "bold 60px Arial"; // Larger font for the amount
        context.fillText(textAmount, canvas.width / 2, canvas.height - 100);
    
        // Convert the canvas to a data URL and set it as the imageDataURL
        const dataURL = canvas.toDataURL("image/png");
        setImageDataURL(dataURL); // Save the final image URL
      } catch (error) {
        console.error("Error creating template image:", error);
        setError("Failed to generate the template image.");
      }
    };

    if (qrData) {

      qrCode.update({
        data: qrData,
      });

      createTemplateImage(amount, "062-736-7878", referenceNumber);
    }
  }, [qrData, amount, referenceNumber]);

  const encodeTextToUTF16Hex = (text) => {
      return Array.from(text)
          .map((char) => char.charCodeAt(0).toString(16).padStart(4, '0'))
          .join('');
  };

  const calculateCRC = (data) => {
      let crc = 0xFFFF;
      for (let i = 0; i < data.length; i++) {
          crc ^= data.charCodeAt(i) << 8;
          for (let j = 0; j < 8; j++) {
              if ((crc & 0x8000) !== 0) {
                  crc = (crc << 1) ^ 0x1021;
              } else {
                  crc <<= 1;
              }
          }
          crc &= 0xFFFF;
      }
      return crc.toString(16).toUpperCase().padStart(4, '0');
  };

  const generateQRData = (amount, mobileNumber, notes) => {
    const formattedAmount = parseFloat(amount).toFixed(2);
    const encodedNotes = encodeTextToUTF16Hex(notes).toUpperCase();

    // Ensure length fields are zero-padded
    const amountLength = String(formattedAmount.length).padStart(2, '0');

    // Assemble QR data without CRC
    let qrData = `00020101021229390016A000000677010111031514000${mobileNumber}5802TH54${amountLength}${formattedAmount}530376481${encodedNotes.length}${encodedNotes}`;

    // Calculate CRC
    const crc = calculateCRC(qrData + "63");
    qrData += `6304${crc}`;

    return qrData;
  };

  const handleGenerateQRCode = async () => {
      if (!amount || parseFloat(amount) < 50) {
          setError("กรุณากรอกจำนวนเงินที่ถูกต้อง");
          return;
      }

      if (!referenceNumber || !refCode || !action || !otp || !gameid) {
          setError("ข้อมูลไม่ถูกต้องกรุณาตรวจสอบ URL");
          return;
      }

      try {
          setLoading(true);

          // Define the API endpoint and headers
          const apiEndpoint = "https://apis.ranworld-adventures.com/v1/server/verify/refcode"; // Replace with your API URL
          const headers = {
            "Accept-Language": "th",
            "Accept": "application/json;charset=UTF-8",
            "Content-Type": "application/json;charset=UTF-8",
            "api-key": "ss3+Nn9s%!$$DJ35bXISQYC!c+^P4x3Rk*2nWB3Q%$k29yUzLAthISeXa%d2LMvG",
          };

          // Make the API call to validate refCode
          const response = await axios.post(
            apiEndpoint,
            { refCode, action, otp, gameid }, // Pass the refCode in the request body
            { headers }
          );

          setLoading(false);

          // Check the response status
          const qrDataRaw = generateQRData(amount, "0627367878", referenceNumber);
          setQrData(qrDataRaw); // Update state
          
      } catch (error) {
          console.error("Error generating QR code:", error);
          const message = error.response?.data || "An unexpected error occurred.";
          setError(message);
      } finally {
          setLoading(false);
      }
  };

  const handleAmountChange = (value) => {
    if (value === "custom") {
        setCustomAmount(true);
        setAmount("");
    } else {
        setCustomAmount(false);
        setAmount(parseInt(value, 10));
    }
  };

  const ErrorModal = ({ title, message, onClose }) => {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <h3>{title}</h3>
          <p>{message}</p>
          <button onClick={onClose}>ปิด</button>
        </div>
      </div>
    );
  };

  return (
      <div style={{ padding: "20px", maxWidth: "400px", margin: "auto", fontFamily: "Arial, sans-serif" }}>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <img 
              src="https://www.truemoney.com/wp-content/uploads/2023/02/truemoney_logo_2x.png" 
              alt="True Money Logo" 
              style={{ maxWidth: "200px", height: "auto" }} 
            />
          </div>

          {/* Display Query Parameters */}
          <div
            style={{
              marginBottom: "20px",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {[
                { title: "Reference Number", value: referenceNumber },
                { title: "ไอดีเกม", value: gameid },
                { title: "รหัสอ้างอิง", value: refCode },
              ].map(({ title, value }) => (
                <li
                  key={title}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 0",
                  }}
                >
                  <span style={{ fontSize: "14px", color: "#555", flex: 1 }}>{title}:</span>
                  <span style={{ fontSize: "14px", color: "#555", flex: 1, textAlign: "right" }}>{value || "N/A"}</span>
                </li>
              ))}
            </ul>
          </div>

          {error && (
            <ErrorModal title="ขออภัย" message={error} onClose={() => setError(null)}/>
          )}

          {!imageDataURL &&<div style={{ marginBottom: "15px" }}>
              <label>
                  จำนวนเงิน:
                  <select
                      value={customAmount ? "custom" : amount}
                      onChange={(e) => handleAmountChange(e.target.value)}
                      style={{
                          display: "block",
                          width: "100%",
                          padding: "10px",
                          marginTop: "5px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                      }}
                  >
                      <option value="">เลือกจำนวนเงิน</option>
                      {predefinedAmounts.map((amt) => (
                          <option key={amt} value={amt}>
                              {amt.toLocaleString()} บาท
                          </option>
                      ))}
                      {/* <option value="custom">ระบุเอง</option> */}
                  </select>
              </label>
          </div>}

          {/* {!imageDataURL && customAmount && (
                <div style={{ marginBottom: "15px" }}>
                    <label>
                        ระบุจำนวนเงิน:
                        <input
                            type="email"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "." || e.key === "e" || e.key === "E") {
                                  e.preventDefault();
                              }
                            }}
                            placeholder="ขั้นต่ำ 50 บาท"
                            style={{
                              display: "block",
                              width: "100%",
                              padding: "10px",
                              marginTop: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                            }}
                            min="50"
                        />
                    </label>
                </div>
            )} */}

          {!imageDataURL &&<button
              onClick={handleGenerateQRCode}
              disabled={loading}
              style={{
                  display: "block",
                  width: "100%",
                  padding: "10px",
                  backgroundColor: loading ? "#ccc" : "#FF8300",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  fontSize: "16px",
                  cursor: loading ? "not-allowed" : "pointer",
              }}
          >
              {loading ? "กำลังสร้าง QR Code..." : "ดำเนินการต่อ"}
          </button>}

          {imageDataURL && (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <img src={imageDataURL} alt="QR Template" style={{ width: "100%" }} />
                  <p style={{ marginTop: "10px", fontSize: "14px", color: "#555", textAlign: "center" }}>
                      กรุณาแตะที่รูป QR Code ค้างไว้ แล้วบันทึกลงคลังภาพ
                  </p>
                  <p style={{ marginTop: "10px", fontSize: "14px", color: "#555", textAlign: "center" }}>
                      จากนั้น เข้าแอฟพลิเคชั่นทรูมันนี่ เลือกสแกน QR Code ที่บันทึกไว้ข้างต้นจากคลังภาพ
                  </p>
                  <p style={{ marginTop: "10px", fontSize: "14px", color: "red", textAlign: "center" }}>
                      QR Code 1 รูป สามารถใช้ชำระเงินได้ 1 ครั้งเท่านั้น
                  </p>
              </div>
          )}
      </div>
  );
};

export default App;
